@tailwind base;

@tailwind components;

@tailwind utilities;

/* Custom properties */
:root {
  --tooltip-text-color: theme('colors.sp-white');
  --tooltip-background-color: theme('colors.sp-neutral.900');
  --tooltip-margin: 4px;
  --tooltip-arrow-size: 6px;
}

/* btn-primary is used for the crispy form submit buttons. See comment in base settings */
.btn-primary {
  @apply sp-btn-action-default sp-btn-md;
}

/***************************/
/* Default style overrides */
/***************************/

html {
  @apply font-sans;
}

@layer base {
  h1,
  h2 {
    @apply text-2xl font-light;
  }

  h3 {
    @apply text-xl font-normal;
  }

  li {
    @apply p-2;
  }
}

input[type='submit'],
input[type='checkbox'] {
  cursor: pointer;
}

/* Sidebar menu links to inherit styling from list*/
.menu-link:hover a {
  color: inherit;
}

/* Ensure any action, even with no href, has pointer cursor. */
a[data-action] {
  cursor: pointer;
}

table {
  @apply border-separate border-spacing-0;
}

th {
  @apply px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left bg-sp-neutral-100 text-sp-neutral-500 whitespace-nowrap;
}

td {
  @apply px-6 py-4 border-t border-sp-neutral-200;
}

tr {
  @apply align-middle;
}

details summary > * {
  @apply inline;
}

summary.config-summary, summary.edit-history {
  color: #333;
  cursor: pointer;
  
  /* Roll our own marker */
  display: flex;
  align-items: center;

  &::marker {
    content: "";
  }
  &::before {
    content: "";
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgYmFzZVByb2ZpbGU9InRpbnkiIGhlaWdodD0iMjRweCIgaWQ9IkxheWVyXzEiIHZlcnNpb249IjEuMiIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PHBhdGggZD0iTTguNTg2LDUuNTg2Yy0wLjc4MSwwLjc4MS0wLjc4MSwyLjA0NywwLDIuODI4TDEyLjE3MSwxMmwtMy41ODUsMy41ODZjLTAuNzgxLDAuNzgxLTAuNzgxLDIuMDQ3LDAsMi44MjggIEM4Ljk3NiwxOC44MDUsOS40ODgsMTksMTAsMTlzMS4wMjQtMC4xOTUsMS40MTQtMC41ODZMMTcuODI5LDEybC02LjQxNS02LjQxNEMxMC42MzQsNC44MDUsOS4zNjYsNC44MDUsOC41ODYsNS41ODZ6Ii8+PC9zdmc+);
    background-size: cover;
    width: 1.25rem;
    aspect-ratio: 1;
    display: block;
    transform: rotate(90deg);
    transition: transform 0.5s ease;
    transform-origin: 50% 50%;
    margin-right: 8px;
  }
  [open] &::before {
    transform: rotate(270deg);
  }

  /* Hover state */
  &:hover {
    color: #666;
  }
}

summary.config-summary {
  &::after {
    content: "";
    margin-left: 8px;
    flex: 1 1 auto;
    border-top: 1px solid #EAECF0;
  }
}


p.config {
  display: flex;
  align-items: center;
}


p.config::after {
  content: "";
  margin-left: 8px;
  flex: 1 1 auto;
  border-top: 1px solid #EAECF0;
}

/**************************/
/* SearchPilot UI Classes */
/**************************/

/* Buttons */

@layer components {
  .sp-btn {
    @apply justify-center transition duration-300 rounded-lg h-fit whitespace-nowrap;
  }

  /* The pseudo class can not be @apply into the base class - see: https://tailwindcss.com/docs/functions-and-directives/#apply */
  .sp-btn:focus {
    @apply ring-4;
  }

  /* Button size classes */
  .sp-btn-sm {
    @apply sp-btn px-3.5 py-2 text-xs;
  }

  .sp-btn-md {
    @apply sp-btn px-4 py-2.5 text-sm;
  }

  .sp-btn-lg {
    @apply px-4.5 py-2.5 text-base;
  }

  .sp-btn-xl {
    @apply px-5 py-3 text-base;
  }

  .sp-btn-2xl {
    @apply py-4 text-lg px-7;
  }

  .sp-btn:disabled {
    @apply cursor-not-allowed;
  }

  /* SP Solid Primary Button default */
  .sp-btn-action {
    @apply text-white bg-sp-primary-600 border-sp-primary-600;
  }

  .sp-btn-action-default {
    @apply sp-btn-action sp-btn-md;
  }

  .sp-btn-action-default:hover {
    @apply hover:bg-sp-primary;
  }

  .sp-btn-action-default:focus {
    @apply bg-sp-primary-600 ring-sp-primary-100;
  }

  .sp-btn-action-default:disabled {
    @apply bg-sp-neutral-200 border-sp-neutral-200;
  }
  /* SP Solid Danger Button */

  .sp-btn-action-solid-danger {
    @apply text-white bg-sp-error-600 border-sp-error-600;
  }

  .sp-btn-action-solid-danger:hover {
    @apply bg-sp-error;
  }

  .sp-btn-action-solid-danger:focus {
    @apply bg-sp-error-600 ring-sp-error-100;
  }
  .sp-btn-action-solid-danger:disabled {
    @apply bg-sp-error-200;
  }

  /* SP Outline Primary Button  */

  .sp-btn-action-outline {
    @apply bg-white text-sp-neutral border-sp-neutral-300;
  }

  .sp-btn-action-outline:hover {
    @apply bg-sp-neutral-50 ring-sp-neutral-100;
  }

  .sp-btn-action-outline:focus {
    @apply bg-white;
  }

  .sp-btn-action-outline:active {
    @apply bg-white;
  }

  .sp-btn-action-outline:disabled {
    @apply text-sp-neutral-300 border-sp-neutral-200;
  }

  /* SP Outline Danger Button  */
  .sp-btn-action-outline-danger {
    @apply bg-white text-sp-error border-sp-error-300;
  }

  .sp-btn-action-outline-danger:hover {
    @apply bg-sp-error-50;
  }

  .sp-btn-action-outline-danger:focus {
    @apply ring ring-sp-error-100;
  }
  .sp-btn-action-outline-danger:active {
    @apply bg-white;
  }

  .sp-btn-action-outline-danger:disabled {
    @apply bg-white border-sp-error-200 text-sp-error-300;
  }

  /* SP Plain Button */

  .sp-btn-plain-primary {
    @apply bg-transparent text-sp-primary;
  }

  .sp-btn-plain-primary:hover {
    @apply bg-sp-primary-50;
  }

  .sp-btn-plain-primary:focus {
    @apply ring-0;
  }

  .sp-btn-plain-primary:disabled {
    @apply text-sp-neutral-300;
  }

  /* SP Plain Button Danger */

  .sp-btn-plain-danger {
    @apply bg-transparent text-sp-error;
  }

  .sp-btn-plain-danger:hover {
    @apply bg-sp-error-50;
  }

  .sp-btn-plain-danger:focus {
    @apply ring-0;
  }

  .sp-btn-plain-danger:disabled {
    @apply text-sp-error-300;
  }

  /* SP Split Buton Button */
  .sp-btn-dropdown-left {
    @apply box-border h-10 border border-white rounded-r-none rounded-l-md;
  }

  .sp-btn-dropdown-right {
    @apply box-border w-10 h-10 px-2 py-2 border border-white rounded-l-none rounded-r-md;
  }

  .sp-btn-on-field {
    @apply rounded-l-none min-w-24;
  }
}

/* Breadcrumbs */

.sp-breadcrumbs-wrapper {
  @apply w-full px-8 py-4 ml-auto bg-white border-b grow-0 border-sp-neutral-200;
}

.sp-breadcrumb-link {
  @apply text-sm font-medium truncate text-sp-neutral-500 focus:outline-none;
}

.sp-breadcrumb-link:hover {
  @apply underline text-sp-primary;
}

.sp-breadcrumb-text {
  @apply text-sm font-medium text-sp-primary;
}

/* UI blocks with a header, footer & content area */

.sp-head-foot-block-container {
  @apply p-0 bg-white border rounded-lg shadow border-sp-neutral-300;
}

.sp-head-foot-block-header {
  @apply px-4 py-3 border-b border-sp-neutral-300;
}

.sp-head-foot-block-inline-content {
  @apply px-4 py-1 bg-white;
}

.sp-head-foot-block-content {
  @apply px-6 py-5 bg-white;
}

.sp-head-foot-block-content-item {
  @apply block px-6 py-4 bg-white;
}

.sp-head-foot-block-content-item:hover {
  @apply bg-sp-neutral-50;
}

.sp-head-foot-block-content-item:focus {
  @apply outline-none bg-sp-neutral-50;
}

.sp-head-foot-block-content-item:last-child {
  @apply rounded-b-lg;
}

/* we don't want last child item within details disclosure to have border radius */
details > ul > li.sp-head-foot-block-content-item:last-child {
  @apply rounded-b-none;
}

.sp-head-foot-block-content:last-child,
.sp-head-foot-block-content:first-child {
  @apply rounded-lg;
}

.sp-head-foot-block-footer {
  @apply px-4 py-4 border-t border-sp-neutral-300;
}

/* Card */

/* using this card wrapper base styling for React Base card component */
.sp-card-wrapper-base {
  @apply flex my-4 align-middle border-b rounded-lg shadow-sm border-sp-neutral-200;
}

/* use this default card wrapper for styling django wrapper elements */
.sp-card-wrapper {
  @apply flex-col min-w-full sp-card-wrapper-base;
}

.sp-card-wrapper.stacked {
  @apply flex-col;
}

.sp-card-wrapper.light {
  @apply bg-white;
}

.sp-card-header-wrapper {
  @apply flex flex-row items-center justify-between gap-3 px-6 py-5 border-b border-sp-neutral-200;
}

/* React Icons */

.react-icons {
  @apply align-middle;
}

/* Form fields & labels */

.sp-input[type='checkbox']:focus,
.sp-input[type='radio']:focus,
.form-radio:focus,
select.sp-input:focus,
textarea.sp-input:focus {
  @apply shadow-xs outline-none ring-4 ring-sp-primary-100 border-sp-primary-300;
}

/* Applying sp-input-field classname to react-phone-number-input input component so we get to style the focus*/
.PhoneInputInput {
  @apply sp-input-field;
}

textarea::placeholder,
input::placeholder {
  @apply text-base text-sp-neutral-500;
}

/* Checkboxes */

.form-checkbox {
  @apply w-4 h-4 bg-white border rounded appearance-none text-sp-primary border-sp-neutral-300;
}

/* Override 'checked' svg and styling */
.form-checkbox:checked {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%237F56D9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'/%3E%3C/svg%3E");
  background-size: 80%;
  color: theme('colors.sp-primary.DEFAULT');
  border: 1px solid theme('colors.sp-primary.600');
  background-color: theme('colors.sp-primary.100');
}

.form-checkbox:hover {
  @apply border-sp-primary bg-sp-primary-100;
}

.form-checkbox:disabled {
  @apply border rounded bg-sp-neutral-100 border-sp-neutral-200;
}

/* Radio buttons */

.form-radio {
  @apply w-4 h-4 bg-white border rounded-full border-sp-neutral-300;
}

/* Override 'circle dot' svg and styling */
.form-radio:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%237F56D9' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  color: theme('colors.sp-primary.DEFAULT');
  border: 1px solid theme('colors.sp-primary.600');
  background-color: theme('colors.sp-primary.100');
}

.form-radio:hover {
  @apply border-sp-primary bg-sp-primary-100;
}

.sp-input:disabled,
textarea:disabled {
  @apply opacity-75 text-sp-neutral-500 bg-sp-neutral-50;
}

.sp-input-field {
  @apply appearance-none shadow-xs w-full px-3.5 py-2.5 border border-sp-neutral-300 rounded-lg text-base text-sp-neutral-900 leading-5;
}

.sp-input-field:focus {
  @apply shadow-xs outline-none ring-4 ring-sp-primary-100 border-sp-primary-300;
}

.sp-input-field-with-error {
  @apply border-sp-error-300;
}

.sp-input-field-with-error:focus {
  @apply shadow-xs outline-none ring-4 ring-sp-error-100 border-sp-error-300;
}

.sp-input-field-with-button {
  @apply rounded-r-none;
}

.sp-input-field-div-wrapper {
  @apply rounded-lg mt-1.5;
}

.sp-select-field {
  @apply block w-full px-3 py-2 text-sm leading-5 text-black bg-white border border-gray-300 rounded-lg;
}

.sp-input-field-label {
  @apply block mb-2 text-sm leading-5 text-sp-neutral-900;
}

.sp-required-asterisk {
  @apply text-xs;
  padding-left: 2px;
}

input::-webkit-search-cancel-button,
input::-webkit-search-decoration {
  -webkit-appearance: none;
}

div.config-input-group > .sp-input-field-div-wrapper {
  @apply mt-0 mr-4 w-full !important
}

div.example-url > div >.sp-input-group >.sp-input-field-div-wrapper {
  @apply mr-4 w-full !important

}

div.example-url > div >.sp-input-group >.sp-input-field-div-wrapper  > .sp-input-field{
  @apply rounded-r-lg !important
}

div.example-url > div >.sp-input-group > .sp-btn-action-outline{
  @apply rounded-l-lg !important
}


/* Fuzzy Search Bar */

#global-fuzzy-search {
  @apply sp-input-field focus-within:w-full;
}

/* Text Area */

.sp-input-textarea {
  @apply block w-full text-base leading-5 border text-sp-neutral-900 border-sp-neutral-300 px-3.5 py-2.5;
}

.sp-input-textarea :focus {
  @apply shadow-xs outline-none ring-4 ring-sp-primary-100 border-sp-primary-300;
}

.sp-input-textarea-error {
  @apply text-red-900 border-red-300;
}

.sp-input-text-area-error:focus {
  @apply border-red-300 ring-red-300;
}

/* Fieldset */

.sp-fieldset {
  @apply pl-3 mb-3 border rounded-md;
}

.sp-fieldset legend {
  @apply px-1 text-sm text-sp-neutral-900;
}

/* Styling for input group and dynamically styling its children */

.sp-input-group, .config-input-group {
  @apply relative flex flex-row items-stretch justify-start text-sm;
}

.sp-input-group .sp-input-field,
.sp-input-group .form-select {
  @apply rounded-none;
}

.sp-input-group:first-child:has(input) {
 @apply rounded-lg
}

.sp-input-group > .sp-input-field-div-wrapper {
  @apply flex-1 my-0;
}

.sp-input-group > :not(:first-child) {
  @apply -ml-px rounded-tl-none rounded-bl-none;
}

.sp-input-group > :not(:last-child) {
  @apply rounded-tr-none rounded-br-none;
}

.sp-input-group:first-child {
  @apply rounded-l-lg;
}

.sp-input-group:last-child {
  @apply rounded-r-lg;
}

.sp-input-group > .sp-input-field-div-wrapper:has(input) {
  @apply flex-1;
}

.sp-input-group > .sp-input-field-div-wrapper:has(select) {
  @apply flex-initial;
}

/* File upload */

.sp-input-file-upload-wrapper {
  @apply w-full px-6 py-4 text-base leading-5 bg-white border rounded-lg shadow-xs appearance-none border-sp-neutral-200 text-sp-neutral-900;
}

.sp-input-file-upload-wrapper:hover {
  @apply bg-sp-primary-25 border-sp-primary-300;
}

.sp-input-file-upload-disabled {
  @apply cursor-not-allowed bg-sp-neutral-50 border-sp-neutral-200;
}

.sp-input-file-upload:hover .sp-input-file-upload-icon-wrapper {
  @apply bg-sp-primary-100 border-sp-primary-50;
}

.sp-input-file-upload:hover .sp-input-file-icon-upload {
  @apply text-sp-primary-600;
}

.sp-input-file-upload-icon-wrapper {
  @apply w-8 h-8 border-4 rounded-3.5xl flex items-center justify-center mb-3;
}

.sp-input-file-upload-icon-wrapper-default {
  @apply sp-input-file-upload-icon-wrapper border-sp-neutral-50 bg-sp-neutral-100;
}

.sp-input-file-upload-icon-wrapper-error {
  @apply sp-input-file-upload-icon-wrapper border-sp-error-50 bg-sp-error-100;
}

.file-upload input.upload {
  display: none;
}

.drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.sp-file-upload-item {
  @apply flex flex-row w-full gap-4 px-4 py-4 text-base border rounded-lg shadow-xs appearance-none;
}

/* Primary navigation elements */

.sp-nav-link {
  @apply relative inline-flex items-center px-6 pt-1 text-sm font-medium leading-5;
}

.sp-nav-link:focus {
  @apply outline-none;
}

.sp-nav-dropdown {
  @apply bg-white shadow-md;
}

/* Secondary navigation elements */

.sp-divider {
  @apply border-t border-sp-neutral-100;
}

.sp-dropdown-menu-wrapper {
  @apply absolute top-0 right-0 z-50 w-64 mt-16;
}

.sp-dropdown-menu-title {
  @apply block px-4 py-2 text-sm leading-5 text-sp-neutral-900;
}

.sp-dropdown-menu-item {
  @apply block text-left px-4 py-2.5 text-sm leading-5 cursor-pointer items-center flex flex-wrap flex-row gap-3;
}

.sp-dropdown-menu-item:first-child:hover {
  @apply rounded-t-lg;
}

.sp-dropdown-menu-item:last-child:hover {
  @apply rounded-b-lg;
}

.sp-dropdown-menu-item:hover {
  @apply bg-sp-neutral-50;
}

.sp-dropdown-menu-item:focus {
  @apply bg-white outline-none;
}

.sp-dropdown-menu-item-indented {
  @apply pl-6;
}

/* Table */

tr:last-child {
  @apply rounded-b-lg;
}

/* Custom CRO results tooltip */

.cro-results-tooltip{
  display: inline-block;
}

.cro-results-tooltip:hover:after{
  content: attr(data-tip);
  position: absolute;
  padding: 15px;
  border: 1px solid gray;
  background: black;
  color: white;
  font-size: 14px;
  max-width: 320px;
  border-radius: 10px;
  overflow-wrap: break-word;
  white-space: pre-wrap !important;
  z-index: 99999;
  text-transform: none !important;
  text-align: center;
}

/* Tooltip */

.sp-tooltip-wrapper {
  @apply relative inline-block;
}

/* Absolute positioning */
.sp-tooltip {
  position: absolute;
  border-radius: 8px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 12px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  z-index: 100;
  white-space: normal;
  min-height: 32px;
  min-width: 100px;
  max-width: 320px;
  width: max-content;
}

/* CSS border triangles */
.sp-tooltip::before {
  content: ' ';
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.sp-tooltip.top {
  bottom: 115%;
  left: 50%;
}
/* CSS border triangles */
.sp-tooltip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.sp-tooltip.right {
  left: 115%;
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.sp-tooltip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.sp-tooltip.bottom {
  top: 115%;
  left: 50%;
}
/* CSS border triangles */
.sp-tooltip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.sp-tooltip.left {
  left: auto;
  right: 115%;
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.sp-tooltip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}

/* Pagination Styling */

.sp-pagination-btn-group {
  @apply relative z-0 inline-flex my-1 -space-x-px rounded-lg shadow-xs;
}

.sp-pagination-btn-item {
  @apply relative inline-flex items-center px-4 py-2.5 border text-sm border-sp-neutral-300 text-sp-neutral-800 bg-sp-white font-medium;
}

.sp-pagination-btn-item:first-of-type {
  @apply rounded-l-lg;
}

.sp-pagination-btn-item:last-of-type {
  @apply rounded-r-lg;
}

.sp-pagination-btn-item.selected {
  @apply z-10 font-semibold bg-sp-neutral-50;
}

.sp-pagination-btn-item.disabled {
  @apply opacity-50 pointer-events-none;
}

/* Help text (for adjusters / values) */

.sp-help-code {
  @apply p-1 mx-px text-sm text-sp-pink bg-sp-pink-25;
}

/* highcharts title */

.highcharts-title {
  @apply text-2xl font-semibold text-center capitalize !important;
}

/* We want to select the individual column (rect svg el) within the chart to create the top rounded border.  */
/* Note: I've had to reduce the roundness of the columns because I couldn't get the rounded columns to stack without introducing some unwanted white space*/
#summary-chart
  > .highcharts-container
  svg.highcharts-root
  > .highcharts-series-group
  > .highcharts-series
  > rect.highcharts-point {
  clip-path: inset(0% 0% -5px 0% round 5px);
}

/******************************/
/* Fonts & external resources */
/******************************/

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,200&display=swap');

/******************************/
/* Additional utility classes */
/******************************/

.w-max-content {
  width: max-content;
}

.text-xxs {
  font-size: 0.6rem;
}

.min-w-24 {
  width: 6rem;
}

.notification.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Lock scroll toggle for modal*/

.toggle-lock-scroll {
  overflow-y: hidden !important;
}

.spinner {
  --line-color: theme('colors.sp-primary.600');
  --ring-color: theme('colors.sp-primary.50');
  --ring-size: 5;

  font-size: 1rem;
  border-radius: 50%;
}
.spinner .line {
  fill: none;
  stroke: var(--line-color);
  stroke-width: var(--ring-size);
  width: 50px;
  stroke-linecap: round;
  transform-origin: 50% 50%;
  transform: rotate3d(0, 0, 1, 0deg);
  stroke-dasharray: 100 150;
  stroke-dashoffset: 140;
}
.spinner .ring {
  fill: none;
  stroke: var(--ring-color);
  stroke-width: var(--ring-size);
}

/* Animations to fade the notification in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

/* JsonDiff Patch styling */

.diff-modal.jsondiffpatch-delta {
  width: 100%;
  overflow: auto;
}

.jsondiffpatch-added .jsondiffpatch-property-name,
.jsondiffpatch-added .jsondiffpatch-value pre,
.jsondiffpatch-modified .jsondiffpatch-right-value pre,
.jsondiffpatch-textdiff-added {
  white-space: pre-wrap;
}
